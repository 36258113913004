import { CircularProgress, IconButton, Snackbar } from '@material-ui/core';
import UploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import ImageIcon from '@material-ui/icons/Image';
import LinkIcon from '@material-ui/icons/Link';
import * as classnames from 'classnames';
import firebase from 'firebase/compat/app';
import { debounce } from 'lodash';
import ModelBase from 'models/ModelBase';
import * as React from 'react';
import FileUploader from 'react-firebase-file-uploader';
import Zoom from 'react-medium-image-zoom';
import FirebaseStore from 'stores/FirebaseStore';
import { handleChange } from 'utils/FormUtils';
import i18n from 'utils/i18n';
import { ContentCopyIcon } from '../Icons';
import ObserverComponent from '../ObserverComponent';
import TextField from '../TextField/TextField';



const styles = require('./ImageBox.module.scss');


interface ImageBoxProps {
  onUploadSuccess?: () => void,
  onDeleteImage?: () => void,
  placeHolderIcon?: JSX.Element,
  placeHolderText?: JSX.Element,
  className?: string,
  store: FirebaseStore<ModelBase>,
  model: ModelBase,
  imageHeight?: number,
  imageWidth?: number,
  shouldSaveToDb?: boolean,
  shouldAllowEditUrl?: boolean,
}

interface ImageBoxState {
  isUploading: boolean,
  progress: number,
  isEditUrlMode: boolean,
  shouldShowCopiedMessage: boolean,
}

export default class ImageBox extends ObserverComponent<ImageBoxProps, ImageBoxState> {
  state = {
    isUploading: false,
    progress: 0,
    isEditUrlMode: false,
    shouldShowCopiedMessage: false,
  };

  uploader = null;

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };
  handleUploadSuccess = (filename: string) => {
    const { model, store, onUploadSuccess, shouldSaveToDb } = this.props;
    firebase.storage()
      .ref(`images/${store.dbLocation}/${model.id}`)
      .child(filename)
      .getDownloadURL()
      .then(url => {
        model.imageUrl = url;
        model.thumbUrl = '';
        if (!url.includes('.svg')) {
          model.thumbUrl = url.replace(filename, filename.replace('.', '_200x200.'));

          setTimeout(() => {
            if (!model.thumbUrl) {
              return;
            }
            model.thumbUrl = model.thumbUrl.includes('?') ? model.thumbUrl + '&reload' : model.thumbUrl + '?reload';
          }, 5000)
        } else {
          model.thumbUrl = model.imageUrl;
        }

        if (store && shouldSaveToDb) {
          store.addEditItem(model);
        }
        this.setState({ progress: 100, isUploading: false });

        if (onUploadSuccess) {
          onUploadSuccess();
        }
      });
  };

  onChange = (event) => {
    const file: File = event.target.files[0];

    // could autocrop white space and more
    /*const reader = new FileReader();

    reader.onload = function(event) {
      the_url = event.target.result
      $('#some_container_div').html("<img src='" + the_url + "' />")
    }

    // when the file is read it triggers the onload event above.
    reader.readAsDataURL(file);
    */

    this.uploader.startUpload(file);
  }

  handleDeleteImage = () => {
    const { model, shouldSaveToDb, store, onDeleteImage } = this.props;

    model.__thumbUrl = {};
    model.__imageUrl = {};

    model._thumbUrls = {};
    model._imageUrls = {};

    if (store && shouldSaveToDb) {
      store.addEditItem(model, true, ['__imageUrl', '__thumbUrl', '_imageUrls', '_thumbUrls']);
    }
    if (onDeleteImage) {
      onDeleteImage();
    }
  }

  onImageUrlChange = debounce((event) => {
    const { model } = this.props;
    handleChange(model, 'imageUrl')(event);
    handleChange(model, 'thumbUrl')(event);
  }, 1000)

  _render() {
    const { userInfoStore } = this.context;
    const { model, className, placeHolderIcon, placeHolderText, imageHeight, imageWidth, store } = this.props;

    if (!model) {
      return null;
    }

    const { imageUrl, thumbUrl } = model;

    const { user } = userInfoStore;

    const {
      isUploading,
      progress,
      isEditUrlMode,
      shouldShowCopiedMessage,
    } = this.state;

    const zoomContent = (
      <div
        className={styles.logoImage}
        style={{ height: '100%', width: imageWidth, backgroundImage: imageUrl ? `url(${imageUrl})` : '' }}
      >
        {!imageUrl && (placeHolderIcon || <ImageIcon />)}
      </div>
    )

    return (
      <form className={styles.root + ' ' + className} style={{ height: imageHeight, width: imageWidth }}>
        <div className={styles.mainPart} style={{ height: imageHeight, width: imageWidth }}>
          {isUploading && <CircularProgress className={styles.progress} />}

          {!isUploading && imageUrl && (
            <Zoom>
              {zoomContent}
            </Zoom>
          )}

          {imageUrl && !isUploading && (
            <IconButton className={styles.deleteIcon} onClick={this.handleDeleteImage}>
              <DeleteIcon />
            </IconButton>
          )}

          <label className={
            classnames({ [styles.hasImage]: imageUrl })
          }> {/* has to be a label for FileUploader to work for some reason*/}
            {!isUploading && (<>
              {!imageUrl && zoomContent}
              <div className={classnames(styles.uploadButtons, { [styles.hasImage]: imageUrl || placeHolderIcon })}>
                <div className={styles.uploadIcon}><UploadIcon /></div>
              </div>
            </>)}

            <FileUploader
              ref={ref => this.uploader = ref}
              style={{}}
              accept="image/*"
              name="avatar"
              filename={`image_${i18n.language}`}
              storageRef={firebase.storage().ref(`images/${store.dbLocation}/${model.id}`)}
              onChange={this.onChange}
              onUploadStart={this.handleUploadStart}
              onUploadError={this.handleUploadError}
              onUploadSuccess={this.handleUploadSuccess}
              onProgress={this.handleProgress}
              // should change to scale instead of crop
              // maxWidth={1024}
              // maxHeight={1024}
              hidden
            />
          </label>


          {/* for debug only for now, would need a way to download the image link to firebase for CORS to work and rename firebase urls to evalumo-prod  */}
          {!isUploading && !isEditUrlMode && (
            <IconButton className={styles.uploadIcon} style={{ display: 'none', right: 55 }} onClick={() => this.setState({ isEditUrlMode: true })}>
              <LinkIcon />
            </IconButton>
          )}
        </div>
        {isEditUrlMode && (
          <div className={styles.imageUrl}>
            <TextField
              shouldFocusOnMount
              shouldSelectAllOnFocus
              label={i18n.t('Image URL')}
              className={styles.textField}
              value={imageUrl}
              onChange={handleChange(model, 'imageUrl')}
              margin="none"
            />

            <IconButton onClick={() => {
              navigator.clipboard.writeText(imageUrl);

              this.setState({ shouldShowCopiedMessage: true });
              setTimeout(() => { this.setState({ shouldShowCopiedMessage: false }); }, 2000)
            }}>
              <ContentCopyIcon />
            </IconButton>
          </div>
        )}

        <Snackbar
          className={classnames(styles.helpSnackBar)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={!!shouldShowCopiedMessage}
          message={i18n.t('Url copied')}
        />
      </form>
    )
  }
}