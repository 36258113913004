
// MAYBE SEPARATE APP SETTINGS FROM USER, no neeed for user object....
import { or, query, where } from '@firebase/firestore';
import { LATEST_USER_VERSION } from 'constants/Constants';
import { DbLocationType } from 'constants/DbLocationType';
import firebase from 'firebase/compat/app';
import Cookies from 'js-cookie';
import { pick } from 'lodash';
import { action, computed, observable, reaction } from "mobx";
import User from 'models/User';
import FirebaseStore from 'stores/FirebaseStore';
import { UserInfo, firestore } from 'utils/FirebaseInitializedApp';
import { waitOnStoresReady } from 'utils/StoreUtil';
import { isProduction, post } from 'utils/Utils';

export default class UserInfoStore extends FirebaseStore<User> {
  customDbLocation = 'users';

  dbLocationsTypes = new Set([
    DbLocationType.Custom
  ]);

  hasCategories = false;
  hasLocalization = false;

  shouldTrackCreationDate = true;

  applyCachedData(data: any = {}, cascadeOrder = 0) {
    // never cache users (because of special filter field)
    return;
  }

  // very rarely firebase gets stuck in limbo on login
  // this forces user to login again
  loginTimeout: NodeJS.Timeout = null;
  ensureLoginTimeout() {
    if (this.loginTimeout) {
      clearTimeout(this.loginTimeout);
    }

    this.loginTimeout = setTimeout(() => {
      if (!this.firebaseUser) {
        // change undefined to null, which will redirect to login page
        this._firebaseUser = null;
      }
    }, 30000)
  }

  attemptLoadItems() {
    // when logged out, we need this store to marke as ready even when no user
    if (!this.firebaseUser?.email) {
      this.ensureLoginTimeout()

      super.attemptLoadItems();
      return;
    }

    if (!this.stores?.subscriptionsStore?.isReady) {
      return;
    }

    if (this.userEmail !== 'louisp.tremblay@gmail.com') {
      this.advancedQueryFunction = collection => query(
        collection,
        or(
          where(firestore.FieldPath.documentId(), '==', this.userEmail),
          where(firestore.FieldPath.documentId(), '==', this.nonImpersonatedEmail),
        )
      );
    }

    super.attemptLoadItems();
  }

  @action
  /* override */ protected onLoginOrLogout(user: UserInfo) {
    if (this.firebaseUser && !user) {
      // on session expired or logout from different window, 
      // eventually show a login modal, but at least don't allow user to work if not saving to db
      window.location.reload();
    }

    this.isLoading = false;
    this.firebaseUser = user;

    if (user) {
      const params = new URLSearchParams(location.search);
      if (params.has('isApi')) {
        post('https://us-central1-estimo-cb0b2.cloudfunctions.net/apiAuth', { uid: user.uid, ...Object.fromEntries(params) });
      }
    }
  }

  // Create new user in DB when first login
  public onLoadCompleted() {
    if (!this.firebaseUser) {
      return;
    }

    const userFromDb = this.getItem(this.userEmail, true);

    // double check that really user isnt in db, can't afford to be a connection error
    // or we will overwrite user
    if (!userFromDb) {
      this.collections[0].doc(this.userEmail).get({ source: 'server' })
        .then(snapshot => {
          if (!snapshot.exists) {
            this.onLoadCompletedAndVerified();
          } else {
            debugger; // bad!
            window.location.reload();
          }
        }).catch(error => {
          if (navigator.onLine) {
            debugger;
            this.stores.commonStore.error = error.message;
            throw (error);
          }
        })
    } else {
      this.onLoadCompletedAndVerified();
    }
  }

  public onLoadCompletedAndVerified() {
    const { subscriptionsStore, projectsStore } = this.stores;
    const userFromDb = this.getItem(this.userEmail, true);
    let user = userFromDb || new User(this.stores);

    // if logged in to firebase, but User doesn't exist yet in our db, create it
    if (
      this.firebaseUser &&
      // dont create user when impersonating other user
      this.userEmail === this.firebaseUser.email && (
        !userFromDb || userFromDb.displayName !== this.firebaseUser.displayName
      )) {
      Object.assign(
        user,
        pick(this.firebaseUser, ['displayName', 'email', 'photoURL', 'phoneNumber']),
        { id: this.userEmail }
      );

      user.versionOnCreation = LATEST_USER_VERSION;
      user.version = LATEST_USER_VERSION;

      // either brand new user
      user.referral = window.Rewardful?.affiliate?.token;

      this.addEditItem(user);
    }

    if (subscriptionsStore.isTrial && !user.hasUsedFreeFullVersionPeriod && window.Rewardful?.affiliate?.token === 'escadron-reserve') {
      user.hasUsedFreeFullVersionPeriod = true;

      // or existing user newly refered by special affiliate deal
      user.referral = window.Rewardful?.affiliate?.token;

      // MOVE TO BACKEND ASAP
      const subscription = subscriptionsStore.getItem('Monthly');
      subscription._expirationDate = new Date(new Date().setDate(new Date().getDate() + 6 * 7)).toISOString();
      subscriptionsStore.addEditItem(subscription);

      this.addEditItemDebounced(user, true, ['currentBuild', 'activeSubscriptionId', 'hasUsedFreeFullVersionPeriod', 'referral'], false);
    }

    const currentBuild = process.env.REACT_APP_COMMIT_REF;
    if (currentBuild && user.currentBuild !== currentBuild) {
      user.currentBuild = currentBuild;
      this.addEditItemDebounced(user, true, ['currentBuild', 'activeSubscriptionId', 'hasUsedFreeFullVersionPeriod', 'referral'], false);
    }

    if (user.activeSubscriptionId != subscriptionsStore.activeSubscription?.id) {
      user.activeSubscriptionId = subscriptionsStore.activeSubscription?.id || 'Trial';
      this.addEditItemDebounced(user, true, ['currentBuild', 'activeSubscriptionId', 'hasUsedFreeFullVersionPeriod', 'referral'], false);
    }

    // doesnt belong here
    if (!Cookies.get('proxyaccess')) {
      Cookies.set('proxyaccess', 'true', { domain: '.evalumo.com' });
    }

    // beurk
    // here because needs user to be loaded so we can pass to the other stuff.. but still bad
    setTimeout(async () => {
      await waitOnStoresReady([projectsStore]);

      const APP_ID = "g2eepink";
      window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: APP_ID,
        name: this.firebaseUser.displayName, // Full name
        email: this.firebaseUser.email, // Email address (not impersonated)
        created_at: (Math.min(Date.now(), Math.round(projectsStore.oldestProjectDateMiliseconds)) / 1000), // Signup date as a Unix timestamp
        language_override: 'fr',
        custom_launcher_selector: '#intercomLauncher'
      };

      (function (w, t, c, p, s, e) {
        p = new Promise(function (r) {
          w[c] = {
            client: function () {
              if (!s) {
                s = document.createElement(t); s.src = 'https://js.cobrowse.io/CobrowseIO.js'; s.async = 1;
                e = document.getElementsByTagName(t)[0]; e.parentNode.insertBefore(s, e); s.onload = function () { r(w[c]); };
              } return p;
            }
          };
        });
      })(window, 'script', 'CobrowseIO');

      window.CobrowseIO.license = "BU1HwBPWHQkWIw";

      window.CobrowseIO.customData = {
        user_id: this.firebaseUser.email,
        user_name: this.firebaseUser.displayName,
        user_email: this.firebaseUser.email,
      };

      if (isProduction()) {
        (function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic('reattach_activator'); ic('update', w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/' + APP_ID; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (document.readyState === 'complete') { l(); } else if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } } })();

        window.Intercom('boot', {
          app_id: APP_ID,
        });
        window.Intercom('update', {
          "hide_default_launcher": true
        });

        window.profitwell('start', { 'user_email': this.firebaseUser.email });

        window.CobrowseIO.client().then(function () {
          window.CobrowseIO.start();
        });
      }

      // https://github.com/suchipi/get-nested-bounding-client-rect/blob/master/src/index.js
      // Add together the top, left, bottom, and right properties of
      // each DOMRect, but keep the width and height of the first one.
      function mergeRectOffsets(rects: Array<DOMRect>): DOMRect {
        return rects.reduce((previousRect, rect) => {
          if (previousRect == null) {
            return rect;
          }
          const nextTop = previousRect.top + rect.top;
          const nextLeft = previousRect.left + rect.left;

          return {
            top: nextTop,
            left: nextLeft,
            width: previousRect.width,
            height: previousRect.height,
            bottom: nextTop + previousRect.height,
            right: nextLeft + previousRect.width,
            x: nextLeft,
            y: nextTop,
          };
        });
      }
      /*
            window.stonlyScriptModifs = (scriptContent) => {
              scriptContent = scriptContent
                .replaceAll('e===document', 'e?.nodeType==9')
                // memory leak?
                .replaceAll('e.removeEventListener("scroll",x', 'e?.removeEventListener("scroll",x')
                .replaceAll('e.removeEventListener("resize",x', 'e?.removeEventListener("resize",x')
                .replaceAll('"_isInDOM",(function(e){return document.body.contains(e)', '"_isInDOM",(function(e){return document.body.contains(e)');
              return scriptContent;
            }

            window.STONLY_WID = "b1e351d2-4b22-11ed-871a-0a52ff1ec764";
            !function (s, t, o, n, l, y, w, g) {
              s.StonlyWidget || ((w = s.StonlyWidget = function () {
                w._api ? w._api.apply(w, arguments) : w.queue.push(arguments)
              }).scriptPath = n, w.queue = [], (y = t.createElement(o)).async = !0,
                (g = new XMLHttpRequest).open("GET", n + "version?v=" + Date.now(), !0), g.onreadystatechange = function () {
                  4 === g.readyState && (y.src = n + "stonly-widget.js?v=" + (200 === g.status ? g.responseText : Date.now()),
                    (l = t.getElementsByTagName(o)[0]).parentNode.insertBefore(y, l))
                }, g.send())
            }(window, document, "script", "https://stonly.com.merchants.evalumo.com/js/widget/v2/");

            StonlyWidget('identify', this.firebaseUser.email);
      */

      window.addEventListener("message", (event) => {
        if (!event.origin.includes('stonly')) {
          return;
        }

        if (event.data.type === 'currentStep' || event.data.type === 'stepType') {
          console.log('step', event.data.stepId);
        }
        // …
      }, false);


      // To be able to highlight elements in merchant iframe for tutorials
      const originalQuerySelector = document.querySelector;
      document.querySelector = function (selector) {
        return [
          originalQuerySelector.bind(document)(selector),
          ...Array.from(document.querySelectorAll('iframe')).map(frame => frame.contentDocument).map(doc => doc?.querySelector(selector))
        ].filter(a => a)?.[0];
      }

      const originalGetComputedStyle = window.getComputedStyle;
      const originalGetBoundingClientRect = Element.prototype.getBoundingClientRect;

      /*
      
      //needed by stonly iframe highlighting, but messes up the PDF report
      window.getComputedStyle = function (element) {
        if (element.nodeType == 9) {
          return true;
        }
  
        if (element.ownerDocument?.defaultView?.frameElement) {
          element.getBoundingClientRect = function () {
            return this.ownerDocument?.defaultView?.frameElement
              ? mergeRectOffsets([originalGetBoundingClientRect.bind(this)(), this.ownerDocument.defaultView.frameElement.getBoundingClientRect()])
              : originalGetBoundingClientRect.bind(this)();
          }
        }
  
        return [
          originalGetComputedStyle.bind(window)(element),
          ...Array.from(document.querySelectorAll('iframe')).map(frame => frame.contentDocument && frame.contentWindow).filter(a => a).map(win => win?.getComputedStyle(element))
        ].filter(a => a)?.[0];
      }*/

    }, 5000);

    super.onLoadCompleted();
  };

  // Here a value of null (logged out) is different from undefined (unknown yet)
  @computed get user(): User {
    if (this.firebaseUser === undefined) {
      return undefined;
    }

    if (this.firebaseUser === null) {
      return null;
    }

    return this.getItem(this.userEmail, true);
  }

  @computed get nonImpersonatedUser(): User {
    return this.getItem(this.nonImpersonatedEmail) || this.user;
  }

  @computed get isAdmin(): boolean {
    return this.user && (
      this.nonImpersonatedEmail === this.userEmail ||
      !this.user.subusers.get(this.nonImpersonatedEmail)?.includes?.('restricted') ||
      this.nonImpersonatedEmail === 'louisp.tremblay@gmail.com'
    );
  }

  @computed get canChangeEvalumoItems() {
    return (
      this.user &&
        this.isAdmin &&
        this.user.versionOnCreation <= 2
        ? this.user.canChangeEvalumoItems // old users choose either behavior
        : this.disableReadonlyChecks // new users can't change Evalumo measurements and soon other items
    );
  }

  @observable disableReadonlyChecks = false;

  // Doesn't 100% belong in store
  logout = async () => {
    const { language } = this.stores.settingsStore;

    await firebase.auth().signOut();
    window.location.href = '/_logout/?lang=' + language;
  }

  resetPassword = async () => {
    await firebase.auth().sendPasswordResetEmail(this.nonImpersonatedEmail);
    this.logout();
  }

  priorityLoadCheck = reaction(() => (
    this.stores?.subscriptionsStore?.isReady
  ),
    (isReady) => {
      if (isReady) {
        this.attemptLoadItems();
      }
    });
}
